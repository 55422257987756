import { lazy } from "react";
import WithSuspense from "../components/Loaders/WithSuspense";
import { PRIVATE_PATHS, PUBLIC_PATHS } from "./constant";
import { Navigate } from "react-router-dom";

const { LOGIN, FORGOT_PASSWORD, VERIFY_CODE_PASS, RESET_PASSWORD, PASSWORD } =
  PUBLIC_PATHS;

const {
  DASHBOARD,
  MANAGE_TEAMS,
  ADD_TEAM,
  QUICK_ACTION,
  QUICK_ACTION_KYC,
  TRANSACTION,
  DISPUTE_TRANSACTION,
  CREDIT_DISPUTE_TRANSACTION,
  VIEW_USER_TRAN,
  VIEW_BUSINESS,
  USER_MANAGEMENT,
  CARD_MANAGEMENT,
  VIEW_CARD_AUDIT,
  MESSAGE,
  VIEW_TRANSACTIONS,
  PROFILE,
  BUSINESS_MANAGEMENT,
  BUSINESS_PROFILE,
  EDIT_BUSINESS,
  PENDING_APPROVAL,
  SUBMITTED_DOCUMENT,
  UPLOAD_CAC,
  IN_REVIEWS,
  FREELANCE_PROFILE,
  VIEW_USER,
  SAVINGS_MANAGEMENT,
  SAVING_INTEREST,
  EDIT_INTEREST,
  SAVINGS_DETAILS,
  VIEW_CARD_DETAILS,
  VIEW_CARD_REQUEST,
  REVIEW_CARD_REQUEST,
  REVIEW_REQUEST_CARD,
  INVOICE_MANAGEMENT,
  INVOICE_DETAILS,
  AUDIT_INVOICE,
  INVOICE_SUBSCRIPTION,
  INVOICE_MONETIZATION_SETTINGS,
  INVOICE_EDIT_BUNDLE,
  ROLES_AND_PERMISSION,
  CREATE_ROLES,
  ROLES_DETAILS,
  SETTINGS,
  PAYMENT_LINK,
  GLOBAL_ACCOUNT,
  ACCOUNTS_TRANSACTION,
  KYC_MGT,
  KYC_PROFILE,
  KYC_DETAILS,
  GLOBAL_ACC_SETTINGS,
  VIEW_PAYMENT_LINK_PROFILE,
  GLOBAL_ACCOUNT_DETAILS,
} = PRIVATE_PATHS;

const Login = WithSuspense(lazy(() => import("../pages/Authentication/Login")));
const ForgotPassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ForgotPassword"))
);
const VerifyCodePass = WithSuspense(
  lazy(() => import("../pages/Authentication/VerifyCodePass"))
);
const ResetPassword = WithSuspense(
  lazy(() => import("../pages/Authentication/ResetPassword"))
);
const Password = WithSuspense(
  lazy(() => import("../pages/Authentication/Password"))
);

const Dashboard = WithSuspense(
  lazy(() => import("../pages/Dashboard/Dashboard"))
);

const Message = WithSuspense(lazy(() => import("../pages/Dashboard/Message")));

const Teams = WithSuspense(lazy(() => import("../pages/Teams/Teams.js")));
const QuickAction = WithSuspense(
  lazy(() => import("../pages/QuickAction/QuickAction"))
);
const QuickActionKyc = WithSuspense(
  lazy(() => import("../pages/QuickAction/Kyc.js"))
);
const Transaction = WithSuspense(
  lazy(() => import("../pages/Transactions/Transactions.js"))
);
const DisputeTransaction = WithSuspense(
  lazy(() => import("../pages/Transactions/DisputeTransaction.js"))
);

const CardMgt = WithSuspense(
  lazy(() => import("../pages/Management/CardMgt/CardMgt.js"))
);

const Audits = WithSuspense(
  lazy(() => import("../pages/Management/CardMgt/Audits.js"))
);

const UserMgt = WithSuspense(
  lazy(() => import("../pages/Management/UserMgt/UserMgt.js"))
);
const CreditDisputeTransaction = WithSuspense(
  lazy(() => import("../pages/Management/UserMgt/CreditDisputeTransaction.js"))
);

const BusinesMgt = WithSuspense(
  lazy(() => import("../pages/Management/BusinessMgt/BusinessMgt.js"))
);

const BusinessProfile = WithSuspense(
  lazy(() => import("../pages/Management/BusinessMgt/BusinessProfile.js"))
);

const EditBusiness = WithSuspense(
  lazy(() => import("../pages/Management/BusinessMgt/EditBusiness.js"))
);
const PendingApproval = WithSuspense(
  lazy(() => import("../pages/Management/BusinessMgt/PendingApproval.js"))
);

const SubmittedDocument = WithSuspense(
  lazy(
    () =>
      import(
        "../pages/Management/BusinessMgt/BusinessReviews/SubmittedDocument.js"
      )
  )
);

const UploadCac = WithSuspense(
  lazy(
    () => import("../pages/Management/BusinessMgt/BusinessReviews/UploadCac.js")
  )
);

const InReviews = WithSuspense(
  lazy(
    () => import("../pages/Management/BusinessMgt/BusinessReviews/InReviews.js")
  )
);

const ViewUserTransaction = WithSuspense(
  lazy(() => import("../pages/Management/UserMgt/ViewUserTransaction.js"))
);

const ViewBusiness = WithSuspense(
  lazy(() => import("../pages/Management/UserMgt/ViewBusiness.js"))
);

const ViewTransactions = WithSuspense(
  lazy(() => import("../pages/QuickAction/Transactions.js"))
);

const Profile = WithSuspense(
  lazy(() => import("../pages/QuickAction/Profile.js"))
);

const ViewUser = WithSuspense(
  lazy(() => import("../pages/Management/UserMgt/ViewUser.js"))
);

const SavingsMgt = WithSuspense(
  lazy(() => import("../pages/Management/SavingsMgt/SavingsMgt.js"))
);
const SavingInterest = WithSuspense(
  lazy(() => import("../pages/Management/SavingsMgt/SavingInterest.js"))
);

const EditInterest = WithSuspense(
  lazy(() => import("../pages/Management/SavingsMgt/EditInterest.js"))
);

const SavingsDetails = WithSuspense(
  lazy(() => import("../pages/Management/SavingsMgt/SavingsDetails.js"))
);

const ViewCardDetails = WithSuspense(
  lazy(() => import("../pages/Management/CardMgt/ViewCardDetails.js"))
);

const ViewCardRequest = WithSuspense(
  lazy(() => import("../pages/Management/CardMgt/ViewCardRequest.js"))
);
const ReviewCardRequest = WithSuspense(
  lazy(() => import("../pages/Management/CardMgt/ReviewCardRequest.js"))
);

const ReviewRequestCard = WithSuspense(
  lazy(() => import("../pages/Management/CardMgt/ReviewRequestCard.js"))
);

const InvoiceMgt = WithSuspense(
  lazy(() => import("../pages/Management/InvoiceMgt/InvoiceMgt.js"))
);

const InvoiceDetails = WithSuspense(
  lazy(() => import("../pages/Management/InvoiceMgt/ViewInvoiceDetails.js"))
);

const AuditInvoice = WithSuspense(
  lazy(() => import("../pages/Management/InvoiceMgt/ViewAuditTrail.js"))
);

const InvoiceSubscription = WithSuspense(
  lazy(
    () => import("../pages/Management/InvoiceMgt/ViewInvoiceSubscription.js")
  )
);

const InvoiceMonetizationSetting = WithSuspense(
  lazy(
    () =>
      import("../pages/Management/InvoiceMgt/InvoiceMonetizationSettings.js")
  )
);

const EditInvoiceBundle = WithSuspense(
  lazy(() => import("../pages/Management/InvoiceMgt/EditInvoiceBundle.js"))
);

const Roles = WithSuspense(lazy(() => import("../pages/Roles/Roles.js")));

const CreateRoles = WithSuspense(
  lazy(() => import("../pages/Roles/CreateRoles.js"))
);
const AddTeam = WithSuspense(lazy(() => import("../pages/Teams/AddTeam.js")));

const RolesDetails = WithSuspense(
  lazy(() => import("../pages/Roles/ViewRolesDetails.js"))
);

const Settings = WithSuspense(
  lazy(() => import("../pages/Settings/Settings.js"))
);

const GlobalAccount = WithSuspense(
  lazy(() => import("../pages/GlobalAccount/GlobalAcc/GlobalAccount.js"))
);
const GlobalAccountDetails = WithSuspense(
  lazy(() => import("../pages/GlobalAccount/GlobalAcc/GlobalAccountDetails.js"))
);

const AccountsTransaction = WithSuspense(
  lazy(
    () =>
      import("../pages/GlobalAccount/AccountTransaction/AccountTransaction.js")
  )
);

const KycMgt = WithSuspense(
  lazy(() => import("../pages/GlobalAccount/kycMgt/KycMgt.js"))
);
const KycProfile = WithSuspense(
  lazy(() => import("../pages/GlobalAccount/kycMgt/ViewProfile.js"))
);
const KycDetails = WithSuspense(
  lazy(() => import("../pages/GlobalAccount/kycMgt/ViewUserKYCDetails.js"))
);

const GlobalAccountSettings = WithSuspense(
  lazy(() => import("../pages/GlobalAccount/Settings/Settings.js"))
);

const PaymentLink = WithSuspense(
  lazy(() => import("../pages/PaymentLink/PaymentLink.js"))
);

const ViewPaymentLinkProfile = WithSuspense(
  lazy(() => import("../pages/PaymentLink/ViewPaymentLinkProfile.js"))
);

export const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: VERIFY_CODE_PASS, element: <VerifyCodePass /> },
  { path: RESET_PASSWORD, element: <ResetPassword /> },
  { path: PASSWORD, element: <Password /> },

  { path: "*", element: <Navigate to="/login" replace /> },
];

export const PRIVATE_ROUTES = [
  { path: DASHBOARD, element: <Dashboard /> },
  { path: MANAGE_TEAMS, element: <Teams /> },
  { path: QUICK_ACTION, element: <QuickAction /> },
  { path: TRANSACTION, element: <Transaction /> },
  { path: DISPUTE_TRANSACTION, element: <DisputeTransaction /> },
  { path: CREDIT_DISPUTE_TRANSACTION, element: <CreditDisputeTransaction /> },
  { path: USER_MANAGEMENT, element: <UserMgt /> },
  { path: BUSINESS_MANAGEMENT, element: <BusinesMgt /> },
  { path: BUSINESS_PROFILE, element: <BusinessProfile /> },
  { path: EDIT_BUSINESS, element: <EditBusiness /> },
  { path: PENDING_APPROVAL, element: <PendingApproval /> },
  { path: SUBMITTED_DOCUMENT, element: <SubmittedDocument /> },
  { path: UPLOAD_CAC, element: <UploadCac /> },
  { path: IN_REVIEWS, element: <InReviews /> },
  { path: CARD_MANAGEMENT, element: <CardMgt /> },
  { path: VIEW_CARD_AUDIT, element: <Audits /> },
  { path: VIEW_USER, element: <ViewUser /> },
  { path: VIEW_CARD_DETAILS, element: <ViewCardDetails /> },
  { path: VIEW_CARD_REQUEST, element: <ViewCardRequest /> },
  { path: REVIEW_REQUEST_CARD, element: <ReviewRequestCard /> },
  { path: REVIEW_CARD_REQUEST, element: <ReviewCardRequest /> },
  { path: VIEW_USER_TRAN, element: <ViewUserTransaction /> },
  { path: VIEW_BUSINESS, element: <ViewBusiness /> },
  { path: INVOICE_MANAGEMENT, element: <InvoiceMgt /> },

  { path: MESSAGE, element: <Message /> },
  { path: VIEW_TRANSACTIONS, element: <ViewTransactions /> },
  { path: PROFILE, element: <Profile /> },
  { path: FREELANCE_PROFILE, element: <Profile /> },
  { path: SAVINGS_MANAGEMENT, element: <SavingsMgt /> },
  { path: SAVING_INTEREST, element: <SavingInterest /> },
  { path: EDIT_INTEREST, element: <EditInterest /> },
  { path: SAVINGS_DETAILS, element: <SavingsDetails /> },

  { path: INVOICE_DETAILS, element: <InvoiceDetails /> },
  { path: AUDIT_INVOICE, element: <AuditInvoice /> },
  { path: INVOICE_SUBSCRIPTION, element: <InvoiceSubscription /> },
  {
    path: INVOICE_MONETIZATION_SETTINGS,
    element: <InvoiceMonetizationSetting />,
  },
  {
    path: INVOICE_EDIT_BUNDLE,
    element: <EditInvoiceBundle />,
  },
  {
    path: ROLES_AND_PERMISSION,
    element: <Roles />,
  },
  {
    path: ROLES_DETAILS,
    element: <RolesDetails />,
  },
  {
    path: ADD_TEAM,
    element: <AddTeam />,
  },
  {
    path: CREATE_ROLES,
    element: <CreateRoles />,
  },
  {
    path: SETTINGS,
    element: <Settings />,
  },
  {
    path: GLOBAL_ACCOUNT,
    element: <GlobalAccount />,
  },
  {
    path: ACCOUNTS_TRANSACTION,
    element: <AccountsTransaction />,
  },
  {
    path: KYC_MGT,
    element: <KycMgt />,
  },
  {
    path: KYC_PROFILE,
    element: <KycProfile />,
  },
  {
    path: QUICK_ACTION_KYC,
    element: <QuickActionKyc />,
  },
  {
    path: KYC_DETAILS,
    element: <KycDetails />,
  },
  {
    path: GLOBAL_ACC_SETTINGS,
    element: <GlobalAccountSettings />,
  },
  {
    path: GLOBAL_ACCOUNT_DETAILS,
    element: <GlobalAccountDetails />,
  },
  {
    path: PAYMENT_LINK,
    element: <PaymentLink />,
  },
  {
    path: VIEW_PAYMENT_LINK_PROFILE,
    element: <ViewPaymentLinkProfile />,
  },

  { path: "*", element: <Navigate to="/dashboard" replace /> },
  { path: "*", element: <Navigate to="/dashboard" replace /> },
];
