import React, { useState } from 'react';
import { Box, Flex, Text, VStack, Collapse, Image } from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from '@chakra-ui/react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { siderBarData, general } from '../../components/common/constant';
import { BizFlexLogo } from '../../assets/exports';
import { useLogOut } from '../../utils/helper';

const SideBar = () => {
  const logout = useLogOut();
  const [isLoading, setIsLoading] = useState(false);

  const action = () => {
    setIsLoading(true);
    setTimeout(() => {
      logout();
      setIsLoading(false);
    }, 1000);
  };

  const { pathname } = useLocation();
  const [show, setShow] = useState(false);
  const [currentIndex, setCurrentIndex] = useState('');

  const navigate = useNavigate();

  return (
    <Flex
      flexDir="column"
      justifyContent="space-between"
      position={'fixed'}
      zIndex={800}
      w="300px"
      bg="#fff"
    >
      <Box h="100vh">
        <Flex
          bg="#fff"
          pos="sticky"
          mx="24px"
          zIndex={55}
          pt="30px"
          top="0"
          pb="25px"
          borderBottom="1px solid #314169"
          justifyContent="space-between"
          align="center"
        >
          <Image
            src="/assets/logo.png"
            w="160px"
            h="55px"
            objectFit="contain"
          />
        </Flex>

        <Box mx="20px" mt="39px">
          <Box>
            {siderBarData?.map((item, i) => {
              return (
                <VStack
                  key={i}
                  align="stretch"
                  mb="15px"
                  className={!pathname.includes(item?.path) && 'parent_nav'}
                  gap={0}
                >
                  <Flex
                    align="center"
                    p={2}
                    pt={3}
                    cursor="pointer"
                    onClick={() => {
                      if (item.sub && item.name !== 'Management') {
                        navigate(item.path);
                        if (show && currentIndex === item.id) {
                          setShow(false);
                        } else {
                          setShow(true);
                          setCurrentIndex(item.id);
                        }
                      } else if (item.name === 'Management') {
                        if (show && currentIndex === item.id) {
                          setShow(false);
                        } else {
                          setShow(true);
                          setCurrentIndex(item.id);
                        }
                      } else {
                        navigate(item.path);

                        setShow(false);
                        setCurrentIndex('');
                      }
                    }}
                    bg={
                      pathname.includes(item.path) ? '#F2F2F2' : 'transparent'
                    }
                    fontWeight={pathname.includes(item.path) ? '500' : '400'}
                    color={pathname.includes(item.path) ? '#0F172B' : '#0F172B'}
                    _hover={{
                      bg: '#F2F2F2',
                      color: '#0F172B',
                    }}
                    h="45px"
                    borderRadius="10px"
                    transition=".3s ease-in-out"
                    position="relative"
                    justifyContent="space-between"
                  >
                    <Flex align="center" gap="24px">
                      <Box>
                        <Image
                          src={item.icon}
                          w="20px"
                          h="20px"
                          objectFit="contain"
                        />
                      </Box>
                      <Box>
                        <Text fontSize="12px">{item.name}</Text>
                      </Box>
                    </Flex>

                    {item?.sub && (
                      <Flex
                        mt="-5px"
                        color={
                          show && currentIndex === item.id
                            ? '#101928'
                            : '#B4B4B4'
                        }
                      >
                        {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
                      </Flex>
                    )}
                  </Flex>

                  {item?.sub && show && (
                    <Collapse in={show && currentIndex === item.id}>
                      <VStack
                        pl={3}
                        align="stretch"
                        borderBottomRadius={4}
                        pb="2"
                        gap={3}
                        pt={4}
                      >
                        {item?.sub?.map((subItem) => (
                          <Link
                            key={subItem.name}
                            to={subItem.path}
                            style={{
                              padding: '4px 0',
                              fontSize: '12px',
                              textDecoration: 'none',
                              color: pathname.includes(subItem.path)
                                ? '#101928'
                                : '#b4b4b4',
                              fontWeight: pathname.includes(subItem.path)
                                ? '500'
                                : '400',
                            }}
                          >
                            <Flex gap="10px">
                              <Box>
                                <Image src={subItem.icon} />
                              </Box>
                              <Box> {subItem.name}</Box>
                            </Flex>
                          </Link>
                        ))}
                      </VStack>
                    </Collapse>
                  )}
                </VStack>
              );
            })}
          </Box>

          <Box mt="20px">
            <Text mb="28px" fontSize="14px" fontWeight={500} color="#98A2B3">
              General
            </Text>
            {general?.map((item, i) => {
              return (
                <VStack
                  key={i}
                  align="stretch"
                  mb="20px"
                  className={!pathname.includes(item?.path) && 'parent_nav'}
                  gap={0}
                >
                  <Flex
                    align="center"
                    p={2}
                    pt={3}
                    cursor="pointer"
                    onClick={() =>
                      item.sub
                        ? show && currentIndex === item.id
                          ? setShow(false)
                          : !show &&
                            (setShow(true),
                            setCurrentIndex(item.id),
                            setShow(true))
                        : (navigate(item.path),
                          setShow(false),
                          setCurrentIndex(''))
                    }
                    bg={
                      pathname.includes(item.path) ||
                      (show && currentIndex === item.id)
                        ? '#F2F2F2'
                        : 'transparent'
                    }
                    fontWeight={
                      pathname.includes(item.path) ||
                      (show && currentIndex === item.id)
                        ? '500'
                        : '400'
                    }
                    color={
                      pathname.includes(item.path) ||
                      (show && currentIndex === item.id)
                        ? '#0F172B'
                        : '#0F172B'
                    }
                    _hover={{
                      bg: '#F2F2F2',
                      color: '#0F172B',
                    }}
                    h="45px"
                    borderRadius="10px"
                    transition=".3s ease-in-out"
                    position="relative"
                    gap="24px"
                  >
                    <Box>
                      <Image src={item.icon} />
                    </Box>
                    <Box>
                      <Text fontSize="12px">{item.name}</Text>
                    </Box>
                  </Flex>
                </VStack>
              );
            })}
          </Box>

          <Box
            mt="40px"
            p={2}
            fontSize="14px"
            align="center"
            py="30px"
            fontWeight={400}
            cursor="pointer"
            onClick={action}
          >
            {isLoading ? (
              <Flex gap="8px" color="red" align="center">
                <Spinner size="sm" /> Logging Out
              </Flex>
            ) : (
              <Flex gap="8px" align="center" color="#0F172B">
                Log Out
              </Flex>
            )}
          </Box>
        </Box>
      </Box>
    </Flex>
  );
};

export default SideBar;
