import React, { useEffect, useState } from "react";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import { slideshow } from "../../components/common/constant";
import { BsCircle, BsFillCircleFill } from "react-icons/bs";
import { wallet } from "../../assets/exports";

const SideBarr = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlideIndex((slideIndex) => (slideIndex + 1) % slideshow.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const renderCircle = (index) => {
    const isActive = index === slideIndex;
    const color = isActive ? "#F3C948" : "#2463EB";
    const opacity = isActive ? 1 : 0.5;
    return (
      <Box key={index}>
        {isActive ? (
          <BsFillCircleFill size="10px" color={color} opacity={opacity} />
        ) : (
          <BsCircle size="10px" color={color} opacity={opacity} />
        )}
      </Box>
    );
  };

  return (
    <Flex
      minH={"90vh"}
      pos="fixed"
      top="80px"
      right="40px"
      borderRadius="16px"
      align="center"
      justifyContent="center"
      w="664px"
      pb="30px"
      bg="#07142F"
      flexDirection="column"
      style={{ transform: "translateY(-5%)" }}
    >
      <Flex flexDir="column" align="center" justifyContent="center" flex="1">
        <Box w="450px" pos="relative" h="360px" overflow="hidden">
          <Flex
            pos="absolute"
            w={`${slideshow.length}00%`}
            h="100%"
            transition={`transform ${4000 / 1000}s ease-in-out`}
            transform={`translateX(-${slideIndex * (100 / slideshow.length)}%)`}
          >
            {slideshow.map((data, i) => (
              <Box
                key={i}
                w={`${100 / slideshow.length}%`}
                h="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Image h="350px" src={wallet} />
              </Box>
            ))}
          </Flex>
        </Box>

        <Center flexDir="column" textAlign="center" mt="40px">
          <Text color="#fff" my="10px" fontWeight={500} fontSize="28px">
            Request POS
          </Text>

          <Text fontSize="14px" fontWeight={300} color="#e0e0e0">
            Get a POS machine to manage all your business <br />
            accounts and manage transactions.{" "}
          </Text>
        </Center>
      </Flex>

      <Flex gap="15px" mt="30px">
        {[...Array(slideshow.length)].map((_, index) => (
          <Box key={index}>{renderCircle(index)}</Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default SideBarr;
