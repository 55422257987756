import { useMutation, useQuery } from 'react-query';
import {
  approveBusiness,
  editOneUser,
  editUser,
  editUserDir,
  getAdmin,
  getAllUsers,
  getBusinessReg,
  getBusinessRegCount,
  getOneBusinessReg,
  getOneUser,
  getTransactions,
  getTransactionsSummary,
  getUserKycDocuments,
  getUsersCount,
  updateUserKycDocuments,
} from '../api/mgt';

export const useGetUsersCount = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getUsersCount',
    getUsersCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetBusinessRegCount = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getBusinessRegCount',
    getBusinessRegCount,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAdmin = (options = {}) => {
  const { data, isLoading, refetch } = useQuery('getAdmin', getAdmin, {
    ...options,
  });

  return { data, isLoading, refetch };
};

export const useGetTransactionsSummary = (options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    'getTransactionsSummary',
    getTransactionsSummary,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetAllUsers = (
  options = {},
  start = '',
  end = '',
  status = '',
  page = 1,
  limit = 25,
  searchTerm = ''
) => {
  const { data, isLoading, refetch } = useQuery(
    ['getAllUsers', start, end, status, page, limit, searchTerm],
    getAllUsers,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetTransactions = (
  options = {},
  start = '',
  end = '',
  category = '',
  status = '',
  page = 1,
  limit = 25,
  searchTerm = '',
  type = ''
) => {
  const { data, isLoading, refetch } = useQuery(
    [
      'getTransactions',
      start,
      end,
      category,
      status,
      page,
      limit,
      searchTerm,
      type,
    ],
    getTransactions,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetBusinessReg = (
  options = {},
  start = '',
  end = '',
  status = '',
  page = 1,
  limit = 25,
  searchTerm = ''
) => {
  const { data, isLoading, refetch } = useQuery(
    ['getBusinessReg', start, end, status, page, limit, searchTerm],
    getBusinessReg,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetOneUser = (userRef = '', businessId = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    ['getOneUser', userRef, businessId],
    getOneUser,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useGetOneBusinessReg = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    ['getOneBusinessReg', id],
    getOneBusinessReg,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};

export const useEditOneUser = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(editOneUser, {
    mutationKey: 'editOneUser',
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useApproveBusiness = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(approveBusiness, {
    mutationKey: 'approveBusiness',
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useEditUser = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(editUser, {
    mutationKey: 'editUser',
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useEditUserDir = (options = {}) => {
  const { mutate, isLoading, data } = useMutation(editUserDir, {
    mutationKey: 'editUserDir',
    ...options,
  });
  return { mutate, isLoading, data };
};

export const useUpdateUserKycDocuments = (options = {}) => {
  const { mutate, isLoading } = useMutation(updateUserKycDocuments, {
    mutationKey: 'updateUserKycDocuments',
    ...options,
  });
  return { mutate, isLoading };
};

export const useGetKycDocuments = (id = '', options = {}) => {
  const { data, isLoading, refetch } = useQuery(
    ['getKycDocuments', id],
    getUserKycDocuments,
    {
      ...options,
    }
  );

  return { data, isLoading, refetch };
};
