import React, { useState } from "react";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { IoIosArrowDown, IoMdMenu } from "react-icons/io";
import { Image, useDisclosure, useMediaQuery } from "@chakra-ui/react";
import SideDrawer from "./SideDrawer";
import {
  timer,
  notification,
  sms,
  searchbar,
  logout,
  personIcon,
} from "../../assets/exports";
import { useNavigate } from "react-router-dom";
import NotificationDrawer from "../../components/modals/NotificationDrawer";
import { useGetAdmin } from "../../services/query/mgt";

const Header = () => {
  const [isMobile] = useMediaQuery("(max-width: 1226px)");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { data } = useGetAdmin();
  const [open, setOpen] = useState(false);

  return (
    <Flex
      flexDirection="column"
      bg="darkBg"
      pos="fixed"
      w="100%"
      h="104px"
      zIndex={700}
      pt={isMobile ? "30px" : "25px"}
      color="#fff"
    >
      <Flex
        justifyContent="space-between"
        align="center"
        pl={isMobile ? "20px" : "320px"}
        pr={isMobile ? "20px" : "48px"}
        w="full"
      >
        {isMobile && (
          <Flex justifyContent="space-between" align="center" w="full">
            <Flex
              color="#BDBDBD"
              borderRadius="20px"
              border="1px solid rgba(104, 132, 202, 0.5)"
              p="7px"
              onClick={onOpen}
              w="fit-content"
              ml={isMobile ? "25px" : "320px"}
              cursor="pointer"
            >
              <IoMdMenu size="20px" />
            </Flex>
          </Flex>
        )}

        {!isMobile && (
          <Flex justifyContent="space-between" width="full">
            <Box>
              <Flex>
                <InputGroup pb="15px" px="24px">
                  <InputLeftElement m="15px" h="62px">
                    <Image src={searchbar} pb="30px" pl="10px" />
                  </InputLeftElement>
                  <Input
                    h="56px"
                    w="516px"
                    bg="#0A0E1B"
                    pr="40px"
                    color="#545B6A"
                    placeholder="Search account here"
                    border="1px solid #545B6A"
                    _placeholder={{ fontSize: "13px", color: "#545B6A" }}
                  />
                </InputGroup>
              </Flex>
            </Box>

            <Flex align="center" gap="18px" w="100%" justifyContent="flex-end">
              <Box>
                <Flex
                  border="1px solid rgba(104, 132, 202, 0.30)"
                  rounded="full"
                  align="center"
                  justifyContent="center"
                  onClick={() => navigate("/dashboard/message/create")}
                  w="40px"
                  h="40px"
                >
                  <Image src={sms} w="20px" h="20px" />
                </Flex>
              </Box>
              <Box>
                <Flex
                  border="1px solid rgba(104, 132, 202, 0.30)"
                  rounded="full"
                  align="center"
                  justifyContent="center"
                  w="40px"
                  h="40px"
                  onClick={setShow}
                >
                  <Image src={notification} w="20px" h="20px" />
                </Flex>
              </Box>

              <Flex
                justifyContent="space-between"
                w="40%"
                align="center"
                position="relative"
              >
                <Flex gap="8px" align="center">
                  <Flex
                    border="0px 4px 10px 0px rgba(83, 83, 83, 0.23)"
                    rounded="full"
                    align="center"
                    bg="#e5eeff"
                    justifyContent="center"
                    w="40px"
                    h="40px"
                  >
                    <Image src={timer} w="20px" h="20px" />
                  </Flex>
                  <Box>
                    <Text fontSize="13px">
                      {data?.data?.firstName} {data?.data?.lastName}
                    </Text>
                    <Text fontSize="11px" color="orangeBg">
                      {data?.data?.role?.name}
                    </Text>
                  </Box>
                </Flex>
                {open && (
                  <Box
                    pos="absolute"
                    borderRadius="16px"
                    bg="#fff"
                    color="#444"
                    fontSize="12px"
                    p="10px"
                    right="0"
                    top="70px"
                  >
                    <Flex gap="8px" borderBottom="1px solid #D2D2D2">
                      <Image src={personIcon} />
                      <Text>Odogwu Kelon</Text>
                    </Flex>
                    <Flex gap="8px" pt="5px">
                      <Image src={logout} />
                      <Text>Log Out</Text>
                    </Flex>
                  </Box>
                )}

                <IoIosArrowDown
                  color="#B4B4B4"
                  // onClick={() => {
                  //   setOpen(!open);
                  // }}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>

      <SideDrawer isOpen={isOpen} onClose={onClose} />

      <NotificationDrawer
        isOpen={show}
        onClose={() => {
          setShow(false);
        }}
      />
    </Flex>
  );
};

export default Header;
