export const PUBLIC_PATHS = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  VERIFY_CODE_PASS: "/verify-code-pass",
  RESET_PASSWORD: "/reset-password",
  PASSWORD: "/new-user/password",
};

export const PRIVATE_PATHS = {
  BUSINESS_MANAGEMENT: "/management/business-mgt",
  BUSINESS_PROFILE: "/management/business-mgt/business-profile/:id",
  EDIT_BUSINESS: "/management/business-mgt/edit-business",
  PENDING_APPROVAL: "/management/business-mgt/pending/:id",
  SUBMITTED_DOCUMENT: "/management/business-mgt/document-approval",
  UPLOAD_CAC: "/management/business-mgt/:id/upload-cac",
  IN_REVIEWS: "/management/business-mgt/reviews/:id",
  VIEW_USER: "/management/user-mgt/:id/:businessId",
  DASHBOARD: "/dashboard",
  MESSAGE: "/dashboard/message/create",
  QUICK_ACTION: "/quick-action",
  TRANSACTION: "/payments",
  DISPUTE_TRANSACTION: "/payments/dispute-transaction",
  MANAGE_TEAMS: "/teams",
  ADD_TEAM: "/teams/create",
  MANAGEMENT: "/management/transfer",
  USER_MANAGEMENT: "/management/user-mgt",
  CREDIT_DISPUTE_TRANSACTION: "/management/user-mgt/credit-dispute-transaction",
  VIEW_USER_TRAN: "/management/user-mgt/:id/transactions",
  VIEW_BUSINESS: "/management/user-mgt/business/:id/:businessId",
  SAVINGS_MANAGEMENT: "/management/savings-mgt",
  SAVING_INTEREST: "/management/savings-mgt/view-interest-rate",
  EDIT_INTEREST: "/management/savings-mgt/edit-savings",
  SAVINGS_DETAILS: "/management/savings-mgt/details/:id",
  CARD_MANAGEMENT: "/management/cards-mgt",
  VIEW_CARD_REQUEST: "/management/cards-mgt/view-card-request",
  REVIEW_CARD_REQUEST: "/management/cards-mgt/card-request",
  REVIEW_REQUEST_CARD: "/management/cards-mgt/review-request-card",
  VIEW_CARD_AUDIT: "/management/cards-mgt/:id/audits",
  VIEW_TRANSACTIONS: "/quick-action/view-transactions/:id",
  PROFILE: "/quick-action/profile/:id",
  QUICK_ACTION_KYC: "/quick-action/kyc",
  VIEW_CARD_DETAILS: "/management/cards-mgt/:id",
  INVOICE_MANAGEMENT: "/management/invoice-mgt",
  INVOICE_DETAILS: "/management/invoice-mgt/details/:invoiceNumber/:businessId",
  AUDIT_INVOICE: "/management/invoice-mgt/audit/:invoiceId/:businessId",
  INVOICE_SUBSCRIPTION: "/management/invoice-mgt/subscription/:businessId",
  INVOICE_MONETIZATION_SETTINGS: "/management/invoice-mgt/invoice-sub",
  INVOICE_EDIT_BUNDLE: "/management/invoice-mgt/invoice-sub/:id",
  ROLES_AND_PERMISSION: "/roles",
  ROLES_DETAILS: "/roles/details/:id",
  CREATE_ROLES: "/roles/create-roles",
  SETTINGS: "/settings",
  PAYMENT_LINK: "/payment-link",
  VIEW_PAYMENT_LINK_PROFILE: "/payment-link/:id",
  GLOBAL_ACCOUNT: "/global-account",
  ACCOUNTS_TRANSACTION: "/global-account/account-transaction",
  KYC_MGT: "/global-account/kyc-management",
  GLOBAL_ACC_SETTINGS: "/global-account/settings",
  GLOBAL_ACCOUNT_DETAILS: "/global-account/details",
  KYC_PROFILE: "/kyc-managemnet/view-profile",
  KYC_DETAILS: "/kyc-managemnet/view-user-details",
};
