import React, { useEffect } from 'react';
import { Box, Flex, Text, useMediaQuery, Image } from '@chakra-ui/react';
import SideBarr from './NonAuthLayout/SideBarr';
import { useLocation } from 'react-router-dom';
import SideBar from './AuthLayout/SideBar';
import Header from './AuthLayout/Header';

export const AuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');

  // const location = useLocation();

  // useEffect(() => {
  //   const events = [
  //     'mousedown',
  //     'mousemove',
  //     'keydown',
  //     'scroll',
  //     'touchstart',
  //   ];
  //   let timeoutId;

  //   const resetIdleTimer = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       sessionStorage.clear();
  //       window.location.href = '/login';
  //     }, 420000); // 7 minutes
  //   };

  //   events.forEach((event) => window.addEventListener(event, resetIdleTimer));

  //   timeoutId = setTimeout(() => {
  //     sessionStorage.clear();
  //     window.location.href = '/login';
  //   }, 420000);

  //   return () => {
  //     clearTimeout(timeoutId);
  //     events.forEach((event) =>
  //       window.removeEventListener(event, resetIdleTimer)
  //     );
  //   };
  // }, []);

  return (
    <Box
      overflowX={isMobile ? 'hidden' : ''}
      color="#000"
      fontFamily="Poppins"
      bg="#fff"
      position="relative"
    >
      <Header />
      <Box minH="100vh" overflowX="hidden">
        {!isMobile && <SideBar />}
        <Box
          w="100%"
          pt={isMobile ? '170px' : '125px'}
          minH="100vh"
          color="#828282"
          bgColor="#F8F8F8"
          pl={!isMobile ? '320px' : '20px'}
          pr={!isMobile ? '24px' : '20px'}
          pb={10}
          bg="#F8F8F8"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export const NonAuthLayout = ({ children }) => {
  const [isMobile] = useMediaQuery('(max-width: 1226px)');
  const location = useLocation();

  return (
    <Box color="#000" fontFamily="Poppins" bg="#fff" position="relative">
      <Flex minH="100vh">
        <Flex pb="30px" flexDir="column" w={!isMobile ? '50%' : '100%'}>
          <Box bg="#fff" pos="sticky" top="0" py="20px" px="38px">
            <Flex justifyContent="space-between" align="center">
              <Flex gap="13px" align="center">
                <Image
                  src="/assets/logo.png"
                  ml="40px"
                  w="150px"
                  h="55px"
                  objectFit="contain"
                />
              </Flex>
              {location.pathname?.includes('/register') && (
                <Box px="12px" py="5px" bg="#E8EEFD" rounded="full">
                  <Text fontSize="13px" fontWeight={500}>
                    STEP {location.pathname?.includes('1') ? '1/2' : '2/2'}
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
          <Box
            flex="1"
            pt={!isMobile ? '0px' : 10}
            px={!isMobile ? '80px' : 10}
          >
            {children}
            {(location.pathname?.includes('/register') ||
              location.pathname?.includes('/login')) && (
              <Flex
                mt="15px"
                mb="50px"
                fontSize="14px"
                gap=".5rem"
                justifyContent="center"
              ></Flex>
            )}
          </Box>
        </Flex>
        {!isMobile && <SideBarr />}
      </Flex>
    </Box>
  );
};
