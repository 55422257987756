import React from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  Box,
  Flex,
  Text,
  Image,
} from "@chakra-ui/react";
import {
  mark,
  Avatar,
  blackAvatar,
  cap,
  woman,
  white,
  icon,
} from "../../assets/exports";
import { IoIosArrowDown } from "react-icons/io";

const NotificationDrawer = ({ isOpen, onClose }) => {
  return (
    <Drawer
      autoFocus={false}
      size="md"
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerContent py="20px" bgColor="#fff" color="#000" borderRadius="8px">
        <DrawerBody p={0} overflowY="scroll">
          <Flex
            justifyContent="space-between"
            align="center"
            bg="#fff"
            zIndex={2}
            pos="sticky"
            top="0"
            pb="15px"
            px="20px"
            borderBottom="1px solid #E7E8EA"
          >
            <Flex gap="18px" align="center">
              <Flex gap="5px" align="center">
                <Text
                  fontWeight={500}
                  fontSize={{ base: "12px", md: "14px" }}
                  color="#0F172B"
                >
                  Notifications
                </Text>
                <Text fontSize="12px">All</Text>
                <IoIosArrowDown />
              </Flex>
            </Flex>
            <Flex
              fontSize="12px"
              gap="5px"
              onClick={() => {
                onClose();
              }}
            >
              <Text>mark all as read</Text>
              <Image src={mark} />
            </Flex>
          </Flex>

          <Box pt="43px" px="30px" color="#444" mt="10px">
            <Flex gap="5px" borderBottom="1px solid #E7E8EA">
              <Image src={Avatar} w="40px" h="40px" />
              <Box>
                <Box bg="var(--Grey-50, #F9FAFB)" borderRadius="8px">
                  <Text fontSize="14px" fontWeight={700} color="#1A1F36">
                    {" "}
                    Lex Murphy requested access to UNIX directory <br /> tree
                    hierarchy{" "}
                  </Text>
                </Box>
                <Flex gap="10px" mt="10px">
                  <Box
                    bg="#132447"
                    borderRadius="8px"
                    color="#fff"
                    fontSize="12px"
                    p="5px"
                  >
                    Approved
                  </Box>
                  <Box
                    bg="#fff"
                    borderRadius="8px"
                    color="#132447"
                    fontSize="12px"
                    p="5px"
                    border="1px solid #DDDEE1"
                  >
                    Decline
                  </Box>
                </Flex>
                <Text fontSize="14px" color="#A5ACB8">
                  Today at 9:42 AM
                </Text>
              </Box>
            </Flex>

            <Flex gap="5px" pt="40px" borderBottom="1px solid #E7E8EA">
              <Image src={cap} w="40px" h="40px" />
              <Box>
                <Box bg="var(--Grey-50, #F9FAFB)" borderRadius="8px">
                  <Text fontSize="14px" fontWeight={700} color="#1A1F36">
                    {" "}
                    Lex Murphy requested access to UNIX directory <br /> tree
                    hierarchy{" "}
                  </Text>
                </Box>
                <Text fontSize="14px" color="#A5ACB8">
                  Last Wednesday at 9:42 AM
                </Text>
              </Box>
            </Flex>

            <Flex gap="5px" mt="20px" borderBottom="1px solid #E7E8EA">
              <Image src={woman} w="40px" h="40px" />
              <Box>
                <Box bg="var(--Grey-50, #F9FAFB)" borderRadius="8px">
                  <Text fontSize="14px" fontWeight={600} color="#1A1F36">
                    {" "}
                    Lex Murphy requested access to UNIX{" "}
                  </Text>
                  <Text fontSize="12px" color="#1A1F36">
                    {" "}
                    “Oh, I finished de-bugging the phones, but the <br />{" "}
                    system's compiling for eighteen minutes, or twenty.”{" "}
                  </Text>
                </Box>
                <Text fontSize="14px" color="#A5ACB8">
                  Last Wednesday at 9:42 AM
                </Text>
              </Box>
            </Flex>

            <Flex gap="5px" pt="40px" borderBottom="1px solid #E7E8EA">
              <Image src={blackAvatar} w="40px" h="40px" />
              <Box>
                <Box bg="var(--Grey-50, #F9FAFB)" borderRadius="8px">
                  <Text fontSize="14px" fontWeight={700} color="#1A1F36">
                    {" "}
                    Lex Murphy requested access to UNIX directory <br /> tree
                    hierarchy{" "}
                  </Text>
                </Box>
                <Text fontSize="14px" color="#A5ACB8">
                  Last Wednesday at 9:42 AM
                </Text>
              </Box>
            </Flex>

            <Flex gap="5px" pt="40px" borderBottom="1px solid #E7E8EA">
              <Image src={white} w="40px" h="40px" />
              <Box>
                <Box bg="var(--Grey-50, #F9FAFB)" borderRadius="8px">
                  <Text fontSize="14px" fontWeight={700} color="#1A1F36">
                    {" "}
                    Lex Murphy requested access to UNIX directory <br /> tree
                    hierarchy{" "}
                  </Text>
                </Box>
                <Text fontSize="14px" color="#A5ACB8">
                  Last Wednesday at 9:42 AM
                </Text>
              </Box>
            </Flex>

            <Flex gap="5px" pt="40px">
              <Image src={icon} w="40px" h="40px" />
              <Box>
                <Box bg="var(--Grey-50, #F9FAFB)" borderRadius="8px">
                  <Text fontSize="14px" fontWeight={700} color="#1A1F36">
                    {" "}
                    Lex Murphy requested access to UNIX directory <br /> tree
                    hierarchy{" "}
                  </Text>
                </Box>
                <Text fontSize="14px" color="#A5ACB8">
                  Last Wednesday at 9:42 AM
                </Text>
              </Box>
            </Flex>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
export default NotificationDrawer;
